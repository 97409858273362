<template>
  <div>
    <b-modal
      ref="agent-model-subscriptions-modal"
      title="Suscripciones de talento"
      ok-title="Cerrar"
      ok-variant="secondary"
      size="xl"
      ok-only
    >
      <div v-if="!currentSubscription">
        <b-alert
          show
          variant="danger"
          class="p-2"
        >
          <p>No existe ninguna suscripción</p>
          <!--          <div class="text-right">
            <button class="btn btn-primary">
              Generar nueva
            </button>
          </div>-->
        </b-alert>
      </div>

      <div v-if="currentSubscription">
        <div class="row">
          <div class="col-12">
            <span
              v-if="currentSubscription && currentSubscription.getAttr('id')"
              class="badge mb-2"
              :class="[badgeClass]"
              @click="openModal"
            >
              {{ currentSubscription.getAttr('status') }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Plan</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="currentSubscription.getAttr('model_agent_subscription_plan')"
              >
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Fecha de inicio</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="currentSubscription.getAttr('start_date')"
              >
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Fecha de finalización</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="currentSubscription.getAttr('end_date')"
              >
            </div>

          </div>
        </div>
      </div>

      <hr>

      <div class="row mb-2">
        <div class="col-12">
          <h5>Historial de suscripciones</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table-component
            :model="model"
            :permissions="{ create: false, update: false, delete: false, filter: false }"
            :init-filter="initFilter"
          />
        </div>
      </div>
    </b-modal>
    <span
      v-if="currentSubscription && currentSubscription.getAttr('id')"
      class="badge cursor-pointer"
      :class="[badgeClass]"
      @click="openModal"
    >
      {{ currentSubscription.getAttr('status') }}
    </span>

    <span
      v-else
      class="badge bg-danger cursor-pointer"
      @click="openModal"
    >
      Sin suscripción
    </span>
  </div>
</template>

<script>
import { BModal, BAlert } from 'bootstrap-vue'
import TableComponent from '@/dc-it/components/TableComponent.vue'
import ModelAgentSubscription from '@/dc-it/models/ModelAgentSubscription'
import { nextTick } from '@vue/composition-api'

export default {
  name: 'ManageSubscriptionsModal',
  components: {
    BModal, BAlert, TableComponent,
  },
  props: {
    currentSubscription: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showModal: false,
    model: ModelAgentSubscription,
    initFilter: [],
  }),
  computed: {
    badgeClass() {
      switch (this.currentSubscription.getAttr('status', false).toString()) {
        case '0':
          return 'badge-danger'
        case '1':
          return 'badge-warning'
        case '2':
          return 'badge-success'
        default:
          return 'badge-secondary'
      }
    },
  },
  methods: {
    openModal() {
      this.$refs['agent-model-subscriptions-modal'].show()
      nextTick(() => {
        this.initFilter = [
          {
            field: 'model_agent_id',
            value: this.currentSubscription.getAttr('model_agent_id'),
          },
        ]
        this.showModal = true
      })
    },
  },
}
</script>

<style scoped>

</style>
